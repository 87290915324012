import { defineStore, acceptHMRUpdate } from 'pinia'
import siteConfig from '@/config.json'
import axios from 'axios'
import sha1 from 'js-sha1'

import { usDateFormatToYYYYMMDD, dateToYYYYMMDD } from '@/helpers/datetime'
import { useClassStore } from '@/stores/ClassStore'
import { useInstructorStore } from '@/stores/InstructorStore'
import { useCartStore } from '@/stores/CartStore'
import router from '@/router'
import { useRoute } from 'vue-router'

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      loggedIn: false,
      loggedOut: false,
      redirectTo: null,
      firstName: null,
      account: null,
      token: null,
      loading: true,
      bendLoggedIn: false,
      preAuthorizedEmails: [],
      accountItems: [],
      balance: 0,
      credit: 0,
      fetchedBalance: false,
      fetchingBalance: false,
      campaigns: [],
      fetchedMemberships: false,
      membershipsInHousehold: [],
      groupIds: [],
      registrationFees: [],
      savedPayments: [],
    }
  },

  getters: {
    isStaff() {
      return this.account && (!!this.account.staff || this.account?.email === 'kostas@artcentercanvas.com')
    },

    isActiveInstructor() {
      return this.account?.instructor
    },

    async instructorClasses() {
      if (!this.isActiveInstructor) return []

      const classStore = useClassStore()
      let instructorId = this.account.personId
      const { data } = await axios.get(`${siteConfig.apiBaseUrl}/reports/queries/class-by-instructor-id?instructor_id=${instructorId}&instructor2_id=${instructorId}`)
      const classPromises = data.ok ? data.results.map(cls => classStore.fetchClassById(cls.catalog_id)) : []
      const classes = await Promise.all(classPromises)
      return classes
    },

    wholeHouseholdMembership() {
      const mem = this.membershipsInHousehold?.find((m) => {
        if (m.household === 1 && m.expire_date) {
          // console.warn("hmmmm")
          const expDate = m.expire_date.slice(0, 10)
          const currentDate = dateToYYYYMMDD(new Date())
          if (currentDate <= expDate) {
            return true
          }
        }
        return false
      })
      if (!mem) return null
      return mem
    },

    hasWholeHouseholdMembership() {
      return !!this.wholeHouseholdMembership
    },

    hasWholeHouseholdMembershipWithDiscount() {
      const mem = this.wholeHouseholdMembership
      return !!mem && mem.discounts
    },
    memberWithActiveMembership() {
      const today = (new Date()).toISOString()
      const membership = this.membershipsInHousehold?.find((m) => m.expire_date > today)

      return !!(membership)
    },
    isActiveMember() {
      if (this.hasWholeHouseholdMembership) {
        return true
      }

      // XXX Temp fix
      // if (siteConfig.key === 'potters' && this.account?.member) return true

      if (!this.account || !this.account.member) return false

      let today = new Date()
      let todaysDateStandard = today.toISOString().split('T')[0]

      const expDate = usDateFormatToYYYYMMDD(this.account.memberExpirationDate, 2000, true)
      return expDate > todaysDateStandard
    },

    isPreAuthorized() {
      return this.loggedIn && this.account && this.preAuthorizedEmails.includes(sha1(this.account.email))
    },

    defaultDonationCampaign() {
      if (!this.campaigns || !siteConfig.features?.campaigns?.enabled) { return null }
      const route = useRoute()
      const defaultId = route.query?.campaignId || siteConfig.features?.campaigns?.defaultId
      return this.campaigns?.find(c => c.campaignId === defaultId) || this.campaigns?.filter(c => c.publish === true && c.closed === false)?.[0]
    },

    hasCompleteProfile() {
      let ok = true

      if (!this.account) {
        return ok
      }

      ok = ok && !!this.account.firstName
      ok = ok && !!this.account.lastName
      ok = ok && !!this.account.email

      if (ok && !siteConfig.features.genderIsOptionalInProfiles) {
        ok = ok && !!this.account.gender
      }

      ok = ok && !!this.account.dob
      ok = ok && !!this.account.homePhone

      if (siteConfig.features.onlyRequireZipCodeForAddress) {
        ok = ok && !!this.account.addrZip
      } else {
        ok = ok && !!this.account.addrStreet1
        ok = ok && !!this.account.addrCity
        ok = ok && !!this.account.addrState
        ok = ok && !!this.account.addrZip
      }

      if (ok && siteConfig.features.includeRaceFieldInProfiles && !siteConfig.features.raceIsOptionalInProfiles) {
        ok = ok && !!this.account.race
      }
      if (ok && siteConfig.features.includeEthnicityFieldInProfiles) {
        ok = ok && !!this.account.ethnicity
      }
      if (ok && siteConfig.features.includePhotoReleaseInProfiles) {
        ok = ok && !!this.account.udef1
      }
      if (ok && siteConfig.features.includeMealPlanInProfiles) {
        ok = ok && !!this.account.udef2
      }
      if (ok && siteConfig.features.requireEmergencyContactInfo) {
        ok = ok && !!this.account.emergencyContact
      }

      return ok
    },
  },

  actions: {
    isActiveMemberWithDiscount(userId = this.account?.personId) {
      if (this.hasWholeHouseholdMembershipWithDiscount) return true

      const membership = this.membershipsInHousehold?.find((m) => m.member_id === userId)

      return !!(membership && membership.discounts === 1 && membership?.expire_date > (new Date()).toISOString());
    },

    userProfile(id) {
      return this.account?.householdMembers?.find((person) => person.personId === id)
    },

    discountPercentForUser(userId = this.account?.personId, type = 'class') {
      if (!siteConfig.features.dynamicMemberPrice) return
      const membership = this.wholeHouseholdMembership || this.membershipsInHousehold?.find((m) => m.member_id === userId)
      const membershipsInCart = useCartStore()?.membershipsInCart
      if (!membership && !membershipsInCart) return

      membership ? membership.categoryId = membership?.category_id : null // Standardize categoryId property name
      const highestDiscount = [...membershipsInCart, membership]?.filter(m => m !== null && m !== undefined)?.reduce((acc, m) => {
        const discount = siteConfig.memberships.find((mem) => mem.categoryId === m.categoryId)?.discounts?.['class'];

        // Check if discount is defined and has an 'amount' property
        if (discount && typeof discount.amount === 'number') {
          // Initialize acc with the current object if acc is not defined
          if (!acc) {
            return { ...m, discount: discount.amount };
          }

          // Update acc if discount is greater than acc's discount
          if (discount.amount > acc.discount) {
            return { ...m, discount: discount.amount };
          }
        }

        return acc;
      }, null);
      return siteConfig.memberships.find((mem) => mem.categoryId === highestDiscount?.categoryId)?.discounts?.[type]?.amount
    },
    async flushCaches() {
      // XXX TODO Use existing method on userStore?
      axios
        .get(`${siteConfig.apiBaseUrl}/classes/open?skipCache=1`)
        .then((response) => {
        })
        .catch((e) => {
          console.log('error flushing caches', e)
        })
      axios
        .get(`${siteConfig.apiBaseUrl}/classes/programs?skipCache=1`)
        .then((response) => {
        })
        .catch((e) => {
          console.log('error flushing caches', e)
        })
      axios
        .get(`${siteConfig.apiBaseUrl}/classes/instructors?skipCache=1`)
        .then((response) => {
        })
        .catch((e) => {
          console.log('error flushing caches', e)
        })
    },

    async fetchPreAuthorized() {
      try {
        const response = await axios.get(`/sites/${siteConfig.key}/hashed.json`)
        const hashed = response.data
        this.preAuthorizedEmails = hashed
      } catch (e) {
        console.log('error fetching waitlist', e)
      }
    },

    async fetchGroups() {
      if (this.loggedIn && this.account?.personId) {
        try {
          const response = await axios.get(
            `${siteConfig.apiBaseUrl}/reports/queries/groups-for-person?person_id=${this.account.personId}`
          )
          this.groupIds = response.data?.results?.map((group) => group.group_id)
        } catch (e) {
          console.log('error fetching groups', e)
        }
      }
    },

    async fetchRegistrationFees() {
      if (this.loggedIn && this.account?.personId) {
        try {
          const response = await axios.get(
            `${siteConfig.apiBaseUrl}/reports/queries/household-registration-fees?household_id=${this.account.householdId}`
          )
          this.registrationFees = response.data.results
        } catch (e) {
          console.log('error fetching groups', e)
        }
      }
    },

    async fetchMembershipsForHousehold() {
      if (this.loggedIn && this.account.householdId) {
        try {
          const response = await axios.get(
            `${siteConfig.apiBaseUrl}/reports/queries/household-memberships?household_id=${this.account.householdId}`
          )
          this.membershipsInHousehold = response.data.results
          this.fetchedMemberships = true
        } catch (e) {
          console.log('error fetching memberships in household', e)
        }
      }
    },

    async fetchBalance() {
      if (!this.account || !this.loggedIn) return
      if (this.fetchingBalance) return

      this.fetchingBalance = true

      axios
        .get(`${siteConfig.apiBaseUrl}/users/${this.account.personId}/balance`)
        .then((response) => {
          this.accountItems = response.data.items

          // XXX FIXME HACK Only show balance if there is just one item
          // if (this.accountItems.length <= 1) {
          this.balance = response.data.balance
          this.fetchedBalance = true
          this.fetchingBalance = false
          // }
        })
        .catch((error) => {
          console.log('fetchBalance error', error)
          this.fetchedBalance = true
          this.fetchingBalance = false
        })
    },

    async fetchCampaigns() {
      axios
        .get(`${siteConfig.apiBaseUrl}/development/campaigns`)
        .then((response) => {
          const data = response.data

          if (data.ok) {
            this.campaigns = data.campaigns
          } else {
            console.warn('error loading campaigns')
          }
        })
        .catch((error) => {
          console.log('fetchCampaigns error', error)
        })
    },

    async fetchSavedPayments() {
      if (!this.account || !this.loggedIn) return
      await axios
        .get(`${siteConfig.apiBaseUrl}/paymentprocessing/findpaymentprofile/${this.account.personId}`)
        .then((response) => {
          let card = response.data['data']
          if (!card || this.savedPayments.filter((p) => p.id === card.providerToken).length > 0) {
            return
          }
          let formattedDate = ''
          if (card.expireDate) {
            const date = new Date(card.expireDate)

            // Get the two-digit month and year
            const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed, so add 1
            const year = date.getFullYear().toString().slice(-2)

            formattedDate = `${month}/${year}`
          }
          this.savedPayments.push({
            type: card.profileType,
            profileId: card.profileId,
            cardRef: card.cardRef,
            holder: card.firstName + ' ' + card.lastName,
            id: card.providerToken,
            expireDate: `${formattedDate}`,
          })
        })
        .catch((error) => {
          console.log('fetchSavedPayments error', error)
        })
    },

    async checkSession() {
      // XXX TODO Validate token
      let session
      try {
        const auth = localStorage.getItem(`${siteConfig.key}Auth`)
        if (auth) {
          session = JSON.parse(auth)
        }
      } catch (error) {
        console.log('error reading session data', error)
        this.loading = false
      }

      if (session) {
        this.firstName = session.name
        this.token = session.token

        await axios
          .post(`${siteConfig.apiBaseUrl}/users/lookup`, { token: this.token })
          .then((response) => {
            this.account = response.data.account
            this.credit = this.account?.acctBal < 0 && !siteConfig.features.disableBalanceCheck ? Math.abs(this.account?.acctBal) : 0
            this.loggedIn = true
            this.fetchGroups()
          })
          .catch((error) => {
            console.log('checkSession user lookup error', error)

            // XXX maybe call logOut() and remove data from localStorage too??
            this.account = null
            this.loggedIn = false
            this.firstName = null
            this.token = null
          })
          .finally((data) => {
            this.loading = false
            this.fetchMembershipsForHousehold()
            this.fetchSavedPayments()
            if (siteConfig.key === 'snowfarm') {
              this.fetchRegistrationFees()
            }
          })
      }
    },

    logOut() {
      this.loggedIn = false
      this.token = null
      this.firstName = null
      this.account = null
      this.loggedOut = true
      this.accountItems = []
      this.balance = 0
      localStorage.removeItem(`${siteConfig.key}Auth`)
      sessionStorage.removeItem(`shouldNotAddDonationAutomatically`)
      const cartStore = useCartStore()
      cartStore.emptyCart()
      router.push('/classes').then(() => {
        location.reload()
      });
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
